@use "../global" as *;
.studeio {
  padding-top: rrem(120);
  padding-bottom: rrem(151);
  position: relative;
}
.studeio::before{
  position: absolute;
  content: "";
  background: #EFEFEF;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include mq("md"){
    z-index: -2;
  }
}

.studeio__inner {
  position: relative;
}

.studeio__inner::before {
  position: absolute;
  content: "";
  background-image: url(../images/studeio_bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.studeio__btn {
  text-align: center;
  display: grid;
  place-items: center;
  gap: rrem(60);

}

.studeio__secTitle {
  width: 100%;
  max-width: rrem(135);
  margin-inline: auto;
  aspect-ratio: 270/65;
  margin-top: rrem(90);
}

.studeio__secTitle img{
  object-fit: cover;
  height: 100%;
}

.studeio__text {
  text-align: center;
  margin-top: rrem(53);
  letter-spacing: 0.05em;
  font-size: rrem(14);
}

.studeio__btn--02 {
  text-align: center;
  display: grid;
  place-items: center;
  gap: rrem(8);
  margin-top: rrem(80);
}

