/* リキッドレイアウト対応のための設定 */
@use "../global" as *;

html {
  //~375px
  @media (max-width: 375px) {
    font-size: vw(375, 16);
  }

  //375px~787px
  font-size: 16px;

  @include mq("md") {
    font-size: vw(strip-unit($inner), 16);
  }

  //inner~max-screen
  @media (min-width: $inner) {
    font-size: 16px;
  }
}
