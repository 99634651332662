@use "../global" as *;
.instructor {
  padding-top: rrem(97.2);
  padding-bottom: rrem(140);
  background: $grade;
  position: relative;
  z-index: -1;
  @include mq("md"){
  z-index: auto;
  padding-bottom: rrem(189);
  background: none;
  }
}

.instructor__bg{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -5;
  background: $grade;

}

.instructor__inner {
  position: relative;
}

.instructor__inner::before {
  @include mq("md"){
    justify-content: center;
    font-size: rrem(63);
    top: rrem(-50);
    padding-left: rrem(196);
  }
  position: absolute;
  content: "INSTRUCTOR";
  top: rrem(-100);
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $bg-white;
  transform: rotate(-10deg);
  z-index: -1;
  margin: 0 calc(50% - 50vw);
  height: rrem(922.52);
  display: flex;
  font-size: rrem(40);
  font-weight: $bold;
  letter-spacing: 0.05em;
  box-shadow: 10px 10px 0px #f8aba6;
  color: transparent;
  -webkit-text-stroke: 2px #F8ABA6;
  text-stroke: 2px #F8ABA6;
  justify-content: end;
}

.instructor__name {
  aspect-ratio: 401/125;
  width: rrem(300);
  margin-inline: auto;
  @include mq("sm"){
    width: rrem(394);
  }
  @include mq("md"){
    margin-left: calc(50% - 18vw);
  }
}

.instructor__name img{
  object-fit: cover;
  height: 100%;
}


.instructor__container {
  @include mq("md"){
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: rrem(81);
    position: relative;
  }
}

.instructor__image {
  aspect-ratio: 840/1438;
  max-width: rrem(420);
  width: 100%;
  @media (max-width: 751px) {
    margin-inline: auto;
  }
  @include mq("md"){
    margin-right: calc(50% - 50vw);
  }
}

.instructor__image img{
  object-fit: cover;
  height: 100%;
}

.instructor__textBox {
  padding-bottom: rrem(50);
  line-height: 3;
  background: $bg-white;
  padding-right: rrem(20);
  padding-left: rrem(20);
  @include mq("md"){
    width: 100%;
    max-width: rrem(400);
    background: none;
    padding-right: 0;
    padding-left: 0;
    margin-top: rrem(82);
  }
}

.instructor__text {
  font-weight: $normal;
  font-size: rrem(13);
  letter-spacing: 0.03em;
  margin-top: rrem(50);
  @include mq("md"){
    margin-top: 0;
    letter-spacing: 0.05em;
  }
}

.instructor__text--02,
.instructor__text--03 {
  margin-top: rrem(30);
  @include mq("md"){
    margin-top: rrem(30);
  }
}

