@use "../global" as *;
.pagetop {
  position: fixed;
  width: rrem(42);
  z-index: 10;
  right: 0;
  transform: translateX(-50%);
  bottom: rrem(112);
  @include mq("md"){
    width: rrem(42);
    left: initial;
    transform: initial;
    right: 5%;
    bottom: rrem(5);
  }
}

.pagetop__link {
  transition: all 0.3s 0s ease;
  aspect-ratio: 42/42;
  width: rrem(42);
}

.pagetop__link {
  object-fit: cover;
  height: 100%;
}