@use "../global" as *;
.header {
    height: rrem(90);
    background-color: #EFEFEF;
    position: relative;
    @include mq("md") {
        height: rrem(40);
        margin-top: rrem(45);
        background: none;
    }
}

.header__inner {
  position: relative;
}

.header__logo{
  position: absolute;
  content: "";
  width: 20%;
  max-width: rrem(74);
  top: 50%;
  transform: translateY(-50%);
  left: rrem(10);
  @include mq("md"){
    position: absolute;
    content: "";
    width: rrem(74);
    height: rrem(74);
    top: -50%;
    left: rrem(40);
    z-index: 5;
    transform: translateY(0);
  }
  @include mq("lg"){
    left: rrem(30);
  }
}

.header__logo--images {
  aspect-ratio: 94/94;
  @include mq("md"){
    aspect-ratio: 74/74;
  }
}

.header__logo img{
  object-fit: cover;
  height: 100%;
}

.header__title{
  font-family: $sabuf;
  font-weight: $black-f;
  font-style: italic;
  font-size: 10vw;
  letter-spacing: 0.05em;
  line-height: calc(87/58);
  text-align: center;
  color: #f8aba6;
  padding-top: rrem(13);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  @include mq("sm"){
    padding-top: 0;
    font-size: rrem(58);
  }
  @include mq("md"){
    display: none;
  }
}

.header__icon {
  display: none;
  @include mq("md"){
    display: flex;
    gap: rrem(8);
  }
  @include mq("lg"){
    gap: rrem(12);
  }
}

.header__icon--link {
  aspect-ratio: 31/31;
  // margin-left: rrem(8);
}
.header__icon--link img{
  object-fit: cover;
  height: 100%;
  width: rrem(31);
  height: rrem(31);
}


.header__nav {
    display: none;
    @include mq("md") {
      position: absolute;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        max-width: rrem(848);
        height: rrem(30);
        z-index: 5;
    }
    @include mq("lg"){
      width: 100%;
    }
}

.header__nav-list {
    display: flex;
    justify-content: center;
    // max-width: rrem(898);
    background: linear-gradient(to right,rgba(245, 129, 124, 0.91) 0%, #ffc0ad 100%);
    border-radius: 50px;
    padding: rrem(10) 0;
    // width: 80%;
    @include mq("lg"){
      padding: rrem(6) rrem(19) rrem(5) rrem(24);
      gap: rrem(56);
      align-items: center;
      width: 100%;
    }
}

.header__nav-item {
    height: inherit;
    margin: 0 13px;
    @include mq("lg"){
      margin: 0;
    }
}

.header__nav-item a {
    height: inherit;
    display: flex;
    align-items: center;
    font-size: rrem(12);
    font-weight: $regular;
    font-family: $mainf;
    line-height: 1;
    color: #fff;
    letter-spacing: -1;
}

.header__hamburger {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    z-index: 999;
    width: 20%;
    max-width: rrem(90);
    height: rrem(90);
    background: linear-gradient(to right,rgba(245, 129, 124, 0.91) 0%, #ffc0ad 100%);
    cursor: pointer;
		transition: .3s;
    @include mq("md") {
        display: none;
    }
}

.header__hamburger.is-open {
    background-color: transparent;
    background: none;
    position: fixed;
}

.header__hamburger span {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: rrem(42);
    height: 1px;
    background-color: #fff;
    transition: .5s;
}

.header__hamburger span:nth-of-type(1) {
    top: rrem(-14);
}

.header__hamburger span:nth-of-type(2) {
    top: 0;
}

.header__hamburger span:nth-of-type(3) {
    top: rrem(14);
}

.header__hamburger.is-open span:nth-of-type(1) {
    top: 0;
    transform: translateX(-50%) rotate(45deg);
}

.header__hamburger.is-open span:nth-of-type(2) {
    opacity: 0;
}

.header__hamburger.is-open span:nth-of-type(3) {
    top: rrem(-2);
    transform: translateX(-50%) rotate(-45deg);
}

.header__drawer {
    display: none;
    position: fixed;
    z-index: 900;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    // height: rrem(1350);
    background: $white;
    overflow-y: scroll;
    scrollbar-width: none;
  }

.header__drawer-overlay{
  position: absolute;
  content: "";
  background: linear-gradient(90deg, rgba(245, 129, 124, 0.8) 0%, rgba(255, 192, 173, 0.8) 100%);
  width: 100%;
  // height: rrem(1350);
  // height: 100vh;
  z-index: -1;
  top: 0;
  padding-top: rrem(68);
  padding-bottom: rrem(200);
}

.header__drawer.is-open{
  background: initial !important;
}

.header__drawer::-webkit-scrollbar {
    display:none;
}

.header__drawer-item a {
    padding: rrem(20) 0;
    display: block;
    font-size: rrem(22);
    font-weight: $regular;
    line-height: calc(36/24);
    letter-spacing: 0.05em;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    opacity: 0.9;
    @include mq("sm"){
      font-size: rrem(30);
    }
}

.header__logo--sp{
  margin-inline: auto;
  margin-bottom: rrem(50);
  width: rrem(74);
  height: rrem(74);
}

.header__spTitle{
  position: absolute;
  content: "";
  z-index: -1;
  left: rrem(-165);
  font-family: $sabuf;
  font-weight: $black-f;
  font-style: italic;
  font-size: rrem(129);
  letter-spacing: 0.05em;
  transform: rotate(90deg);
  color: rgba(0, 0, 0, 0);
  text-shadow: 3px 3px 0 #ffc0ad;
  -webkit-text-stroke: 1px $white;
  text-stroke: 1px $white;
}

.header__lesson{
  background: linear-gradient(to bottom, rgba(245, 129, 124, 0.91) 0%, #ffc0ad 100%);
  width: rrem(64);
  height: rrem(64);
  border-radius: 50%;
  margin-top: rrem(-20);
  padding: rrem(4);
  @include mq("lg"){
    width: rrem(80);
    height: rrem(80);
  }
}

.header__lessonlink{
  font-size: rrem(8);
  color: #fff;
  text-align: center;
  display: grid;
  height: rrem(64);
  place-items: center;
  margin-top: -2px;
@include mq("lg"){
  font-size: rrem(13);
  height: rrem(80);
  
}
}