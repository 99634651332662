@use "../global" as *;
.lesson {
  position: relative;
  padding-top: rrem(47);
  padding-bottom: rrem(144);
}

.lesson::before{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background-image: url(../images/lesson_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  z-index: -2;
  filter: brightness(0.9);
  @include mq("md"){
  background-position: center;
    
  }
}

.lesson__secTitle {
  width: 100%;
  max-width: rrem(104);
  margin-inline: auto;
  aspect-ratio: 210/70;
}

.lesson__secTitle img{
  object-fit: cover;
  height: 100%;
}

.lesson__list {
  width: 100%;
  line-height: 1;
  text-align: center;
}

.lesson__list td{
  letter-spacing: 0.06em;
}

.lesson__list:first-of-type{
  margin-top: rrem(49);
}

.lesson__list:last-child{
  margin-top: rrem(20);
}

.lesson__list tr:not(:first-child),
.lesson__list--02 tr{
  height: rrem(64);
}

.lesson__list--head {
  width: 50%;
  font-size: rrem(16);
  font-family: $sabuf;
  font-weight: $regular;
  line-height: calc(24/16);
  color: #fff;
  background: $grade;

}

.lesson__list--data {
  padding: rrem(10) 0;
  font-size: rrem(12);
  font-weight: $regular;
  line-height: calc(18/12);
  background: $white;
  width: 50%;
  @include mq(md){
      padding: rrem(15) 0;
      font-size: rrem(16);
      line-height: calc(24/16);
  }
}

.lesson__list--02{
  margin-top: rrem(20);
}

.lesson__textBox{
  margin-top: rrem(64);
}

.lesson__text{
  font-size: rrem(13);
  font-weight: $regular;
  line-height: 2;
  padding-left: rrem(14);
  position: relative;
  letter-spacing: 0.05em;
}

.lesson__text::before{
  position: absolute;
  content: "※";
  left: 0;
}

.lesson__btn {
  margin-top: rrem(152);
  text-align: center;
}

