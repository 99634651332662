@use "../global" as *;
.card {
  display: flex;
  flex-direction: column-reverse;
  margin-top: rrem(120);
  @include mq("md"){
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: rrem(60);
    margin-top: rrem(340);
    position: relative;
  }
}

.card:nth-child(1)::after,
.card:nth-child(3n)::after{
  position: absolute;
  content: "";
  background-image: url(../images/rayout__en.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: rrem(438);
  height: rrem(431);
}

.card:nth-child(1)::after{
  right: rrem(-431);
  top: rrem(510);
}

.card:nth-child(3n)::after{
  left: rrem(-431);
  top: rrem(-510);
}

.card:nth-child(1){
  @include mq("md"){
    margin-top: 0;
  }
}

.card:nth-child(even){
  @include mq("md"){
    flex-direction: row-reverse;
    
  }
}

.card:nth-child(even) .card__subTitle,
.card:nth-child(even) .card__text{
  color: #797879;
  line-break: anywhere; 
}

.card__container {
  width: 100%;
  position: relative;
  margin-top: rrem(70);
  @include mq("md"){
    max-width: rrem(460);
    margin-top: 0;
  }
}

.card:nth-child(even)::before{
  position: absolute;
  top: rrem(2055);
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $bg-white;
  transform: skewY(15deg);
  z-index:-1;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  height: rrem(622);
  content: "";
  @include mq("md"){
    top: rrem(-146);
    z-index: -2;
  }

}

.card__subTitle {
  font-weight: $medium;
  font-size: rrem(18);
  letter-spacing: 0.05em;
  text-align: left;
  color: #fafafa;
  @include mq("md"){
    letter-spacing: 0.2em;
  }
}

.card__text {
  font-weight: $regular;
  font-size: rrem(13);
  letter-spacing: 0.05em;
  text-align: left;
  line-height: 32px;
  color: $white;
  margin-top: rrem(77);
  @include mq("md"){
  margin-top: rrem(61);
    
  }
}

.card__titleImage {
  position: absolute;
  content: "";
}

.card__titleImage img{
  object-fit: cover;
  height: 100%;
}

.card__titleImage--01 {
  top: rrem(-16);
  right: rrem(-20);
  aspect-ratio: 423/351;
  width: rrem(188);
  // max-width: rrem(188);
  @include mq("md"){
    top: rrem(-61);
    right: rrem(-33);
  }
}

.card__image {
  aspect-ratio: 480/314;
  box-shadow: 15px 15px 0px 0px rgba(255, 255, 255);
  @include mq("md"){
    width: rrem(480);
  }
}

.card:nth-child(even) .card__image{
  box-shadow: 15px 15px  0px 0px rgba(248, 171, 166);
  @include mq("md"){
    box-shadow: -15px 15px  0px 0px rgba(248, 171, 166);
  }
}

.card__image img{
  object-fit: cover;
  height: 100%;
}

.card__titleImage--02 {
  aspect-ratio: 528/276;
  width: rrem(257);
  right: 0;
  bottom: rrem(-110);
  @include mq("md"){
    bottom: rrem(-142);
  }
}

.card__titleImage--03 {
  aspect-ratio: 406/182;
  width: rrem(202);
  right: 0;
  @include mq("md"){
    bottom: rrem(-140);
  }
}

.card:nth-child(3n) .card__container{
  @include mq("md"){
    top: rrem(-100);
  }
}

html,body{
  overflow-x: hidden;
}