@use "../global" as *;
.qa{
  padding-top: rrem(104.4);
  margin-bottom: rrem(137.7);
  background: $white;
  @include mq("md"){
    padding-top: rrem(72.4);
    background: none;
    // position: relative;
    // z-index: 5;
  }
}

.qa__inner {
  margin: auto;
  padding: 0 rrem(25);
  max-width: rrem(850);
  width: 100%;
}

.qa__secTitle{
  aspect-ratio: 309/82;
  width: rrem(160);
  margin-inline: auto;
}

.qa__secTitle img{
  object-fit: cover;
  height: 100%;
}

.qa__list{
  margin-top: rrem(130.6);
  margin-bottom: rrem(137.7);
}