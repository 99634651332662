@use "../global" as *;
.floating {
  // position: fixed;
  // width: 100%;
  // z-index: 10;
  // right: 0;
  // bottom: 0;
  // display: flex;
  display: none;
  @include mq("md"){
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 0;
    display: flex;
    top: rrem(117);
    display: block;
    width: rrem(58);
  }
}

.floating__link{
  width: 50%;
  height: rrem(100);
  @include mq("md"){
    height: rrem(203);
    width: rrem(58);
    
  }
}

.floating__link01 {
  background: #FC8C84;
  border: 2px solid #FC8C84;
  transition: all 0.3s 0s ease;
  display: grid;
  place-items: center;
  @include mq("md"){
    border-radius: 20px 0px 0px 0px;
    margin-bottom: rrem(2);
  }
}

.floating__link01:hover {
  opacity: 0.6;
}

.floating__link02 {
  background: #FCDCDA;
  border: 2px solid #FCDCDA;
  transition: all 0.3s 0s ease;
  display: grid;
  place-items: center;
  @include mq("md"){
    border-radius: 0px 0px 0px 20px;
  }
}

.floating__link02:hover {
  // border: 2px solid #FCDCDA;
  // background: #FFF;
  opacity: 0.6;
}


.floating__text01 {
  color: $white;
  font-size: 3vw;
  font-style: normal;
  font-weight: $bold;
  line-height: calc(35/20);
  @include mq("md"){
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -ms-writing-mode: vertical-rl;
    color: $white;
    font-size: rrem(13);
    line-height: calc(19.5/13);
    letter-spacing: rrem(3);
    position: relative;
  }
}


.floating__text02 {
  color: #707070;
  font-size: 3vw;
  font-style: normal;
  font-weight: $bold;
  line-height: calc(35/20);
  @include mq("md"){
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -ms-writing-mode: vertical-rl;
    font-size: rrem(13);
    line-height: calc(19.5/13);
    position: relative;
    letter-spacing: rrem(3);
  }
}

// .floating__link02:hover .floating__text02 {
//   color: #FC8C84;
// }
