@use "../global" as *;
.floating-sp{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  @include mq("md"){
    display: none;
  }
}

.floating-sp__inner{
  display: flex;
}

.floating-sp__link{
  width: 50%;
  height: rrem(100);
  transition: all 0.3s 0s ease;
  display: grid;
  place-items: center;
}

.floating-sp__inner a{
  opacity: 1.0;
}

.floating-sp__link01{
  background: #FC8C84;

  color: $white;
  font-size: 3vw;
  font-style: normal;
  font-weight: $bold;
  line-height: calc(35/20);
}

.floating-sp__modal{
  background: #FCDCDA;
  color: #707070;
  font-size: 3vw;
  font-style: normal;
  font-weight: $bold;
  line-height: calc(35/20);
}

/* モーダルCSS */
.modal {
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* モーダル全体のスクロールを無効にする */
  overflow-y: auto;
  background: #fff;
}

.modal__Bg {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.modal__Wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-50%);
  width: 70%;
  max-width: 500px;
  background-color: #fff;
  border: 6px solid rgba(252, 140, 132, 0.5);
  padding-top: rrem(80);
  padding-bottom: rrem(80);
  margin-top: 50px;
  @include mq("sm"){
    padding-top: rrem(139);
    padding-bottom: rrem(125);
  }
}


.modal__Contents{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rrem(80);
}

.Modal__close {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
  font-size: rrem(54);
  color: #F8ABA6;
}

.floating-sp__lesson{
  font-weight: $normal;
  font-size: rrem(16);
  letter-spacing: 0.06em;
  text-align: center;
  color: #fafafa;
  background: #F8ABA6;
  display: block;
  width: 100%;
  padding-top: rrem(32);
  padding-bottom: rrem(32);
  width: 90%;
  @include mq("sm"){
    font-size: rrem(20);
  }
}

@media (max-width: 750px) {
  .modal-open {
    position: fixed;
    width: 100%;
    overflow: hidden; /* モーダルが表示されているときにスクロールを無効にする */
  }
}

/* 以下ボタンスタイル */
button {
  cursor: pointer;
}


