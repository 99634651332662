@use "../global" as *;


.top-title__side{
  display: none;
  @include mq("md"){
    position: fixed;
    display: block;
    font-family: Lato;
    font-weight: 900;
    font-style: italic;
    font-size: 129px;
    letter-spacing: 0.05em;
    text-align: left;
    color: rgba(0, 0, 0, 0);
    text-shadow: 3px 3px 0 #ffc0ad;
    -webkit-text-stroke: 1px $white;
    text-stroke: 1px $white;
    top: 50%;
    left: 0;
    transform: rotate(90deg) translateX(-20%) translateY(100%);
    z-index: -1;
    mix-blend-mode:overlay;
    opacity: 0.6;
  }
}
