@use "../global" as *;
// インナー幅用の基本CSS
.inner {
  width: 100%;
  padding-right: $padding-sp;
  padding-left: $padding-sp;
  margin-right: auto;
  margin-left: auto;
  // @include mq("sm"){
  //   padding-right: 80px;
  //   padding-left: 80px;
  // }
  @include mq("md") {
    max-width: $inner + $padding-pc * 2;
    padding-right: $padding-pc;
    padding-left: $padding-pc;
  }
}
