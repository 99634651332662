@use "../global" as *;
.fv {
  position: relative;
  min-height: rrem(400);
  background: $bg-white;
  z-index: -2;
  @include mq("md"){
    margin-top: rrem(-85);
    z-index: 0;
  }
}

.fv__inner {
  height: inherit;
  min-height: inherit;
}

.fv__title-wrap {
  width: 100%;
  text-align: center;
  padding-left: rrem(35);
  position: relative;
  padding-top: rrem(75.4);
  padding-bottom: rrem(89.6);
  @include mq(md){
    position: absolute;
    z-index: 2;
    top: 47%;
    left: calc(50% - min(50vw, #{rrem(550)}));
    transform: translateY(-50%);
    width: initial;
    margin-left: 25px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.fv__main-title {
  font-size: 6vw;
  // font-size: rrem(47);
  font-weight: $regular;
  line-height: calc(70.5/47);
  letter-spacing: 0.1em;
  text-align: left;
  text-shadow: 0px 1px 1px #fff;
  @include mq(md){
    font-size: rrem(28);
    line-height: calc(42/28);
    color: #949293;
    text-align: start;
  }
}

.fv__main-title span {
  color: #F8ABA6;
}

.fv__sub-title {
  font-size: 4vw;
  // font-size: rrem(37);
  font-weight: $regular;
  letter-spacing: 0.1em;
  text-align: left;
  text-shadow: 0px 1px 1px #fff;
  @include mq(md){
    font-size: rrem(22);
    line-height: calc(55.5/37);
    color: #949293;
    text-align: start;
  }
}

.fv__img,
.fv__img img {
  height: inherit;
  min-height: inherit;
}

.fv__img{
  aspect-ratio: 377/474;
  @include mq("md"){
    aspect-ratio: 1440/900;
  }
}

.fv__img img {
  // width: 100%;
  object-fit: cover;
  height: 100%;
}

.fv__title-img{
  position: absolute;
  width: rrem(412);
  aspect-ratio: 412/258.4;
  top: rrem(15);
  right: 0;
  z-index: -1;
  opacity: 0.6;
  @include mq("md"){
    top: 0;
    right: calc(122% - min(50vw, 34.375rem));
    aspect-ratio: 481/308;
    width: rrem(241);
    opacity: 1;
  }
}

.fv__title-img img{
  object-fit: cover;
  height: 100%;
}

