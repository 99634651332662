@use "../global" as *;
.qa-list__item {
  background-color: #fff;
  border-bottom: 1px solid #707070;
    padding-bottom: rrem(39);
}

.qa-list__item + .qa-list__item {
  margin-top: rrem(40);
}

.qa-list__item-question {
  position: relative;
  font-size: rrem(15);
  text-indent: rrem(-20);
  cursor: pointer;
  position: relative;
  padding: rrem(10) rrem(20);
  border-left: 2px solid #707070; 
  @include mq(md){
      padding: rrem(10) rrem(60) rrem(10) rrem(20);
      display: flex;
      align-items: center;
  }
}

.qa-list__item-question::before {
  content: "";
  position: absolute;
  top: 50%;
  right: rrem(20);
  width: rrem(10);
  height: rrem(10);
  border-top: rrem(3) solid #707070;
  border-right: rrem(3) solid #707070;
  transform: translateY(-50%) rotate(135deg);
  transition: .3s;
  @include mq(md){
      width: rrem(13);
      height: rrem(13);
  }
}

.qa-list__item-question.is-open::before {
  transform: translateY(-50%) rotate(315deg);
}

.qa-list__item-question span {
  margin-right: rrem(20);
}

.qa-list__item-answer {
  display: none;
  font-size: rrem(13);
  text-indent: rrem(-20);
  padding: rrem(10) rrem(36) rrem(10) rrem(20);
  border-left: 1px solid #707070; 
  margin-left: 1px;
  letter-spacing: 0.05em;
  @include mq(md){
      padding: rrem(40) rrem(40) rrem(40) rrem(20);
  }
}

.qa-list__item-answer span {
  margin-right: rrem(20);
}
