@use "../global" as *;

.footer {
  background-color: #F8ABA6;
  padding-bottom: rrem(100);
  @include mq("md"){
  padding-bottom: rrem(7);
  }
}

.footer__inner{
  margin-left: rrem(45);
  margin-right: rrem(25);
  padding-top: rrem(30);
  padding-bottom: rrem(30);
  @include mq("md"){
  padding-bottom: 0;
    
  }
}

.footer__wrap {
  display: flex;
  flex-direction: column;
  @include mq(lg){
      padding-top: rrem(77);
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: rrem(31);
  }
}

.footer__container{
  display: flex;
  gap: rrem(50);
  margin-inline: auto;
  @include mq("md"){
    margin-inline: 0;
  }
  @include mq("lg"){
    gap: rrem(30);
  }
}

.footer__logo {
  max-width: rrem(74);
  width: 100%;
}

.footer__logo a {
  display: block;
}

.footer__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer__nav{
  margin-top: rrem(30);
  @include mq("lg"){
    margin-top: 0;
  }
}

.footer__nav-items {
  display: flex;
  flex-direction: column;
  gap: rrem(30);
  text-align: center;
  @include mq(md){
      flex-direction: row;
      align-items: flex-start;
      gap: rrem(30);
      text-align: left;
  }
}

.footer__nav-item a {
  position: relative;
  display: block;
  font-size: rrem(14);
  font-weight: 600;
  line-height: 1;
  color: #FAFAFA;
  @include mq("md"){
    text-align: center;
  }
}


.footer__nav-item a img{
  width: rrem(20);
}

.footer__company {
  font-size: rrem(20);
  font-weight: $regular;
  font-family: $sansjp;
  color: #FAFAFA;
}

.footer__company--text {
  color: #FAFAFA;
}

.footer__company--tel {
  color: #FAFAFA;
  @include mq("md"){
    pointer-events: none;
  }
}

.footer__nav-itemicon{
  display: flex;
  gap: rrem(30);
  margin-inline: auto;
  @include mq("md"){
    margin-inline: 0;
  }
}

.footer__copyright {
  margin-top: rrem(10);
  display: block;
  font-size: rrem(12);
  line-height: 1;
  letter-spacing: 0.04em;
  text-align: center;
  color: #FAFAFA;
  @include mq(md){
      margin-top: rrem(50);
  }
}

.footer__copyright span {
  text-transform: uppercase;
}