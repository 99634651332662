@use "../global" as *;
.sub-inner{
    padding: 0 rrem(25);
  @include mq("sm"){
    padding: 0 rrem(80);
    
  }
  @include mq("md"){
    max-width: rrem(850);
    margin-inline: auto;
    width: 100%;
    padding: 0 rrem(25);
  }
}