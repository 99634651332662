@use "../global" as *;

.text-box__list:nth-of-type(1){
  margin-top: rrem(57);
}

.text-box__list{
  margin-top: rrem(72);
  @include mq("md"){
    // position: relative;
    // z-index: 5;
  }
}

.text-box__title {
  font-size: rrem(18);
  font-weight: $regular;
  text-align: left;
  letter-spacing: 0.2em;
}

.text-box__text {
  margin-top: rrem(23);
  font-size: rrem(13);
  font-weight: $regular;
  text-align: left;
  letter-spacing: 0.05em;
}
