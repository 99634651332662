@use "../global" as *;
.reasons {
  padding-bottom: rrem(130);
  position: relative;
  @include mq("md"){
    padding-bottom: rrem(83);
  }
}

.reasons::before{
position: absolute;
content: "";
background: linear-gradient(to right,rgba(245, 129, 124) 0%, #ffc0ad 100%);
width: 100%;
height: 100%;
z-index: -2;
opacity: 0.7;
}

.reasons__wrapper {
  width: 100%;
  margin: 0;
  padding-top: rrem(127);
  position: relative;
}

.reasons__wrapper::before{
  position: absolute;
  content: "";
  top: rrem(258);
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $bg-white;
  transform: skewY(15deg);
  z-index: -1;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  height: rrem(622);
  display: flex;
  align-items: end;
  padding-left: rrem(200);
  font-size: rrem(18);
  letter-spacing: 0.05em;
  font-style: italic;
  padding-bottom: rrem(10);
  @include mq("md"){
    content: "mgm.popが選ばれる理由";
    z-index: -2;
    top: rrem(194);
  }
}

.reasons__wrapper::after{
  position: absolute;
  content: "REASONS";
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  height: rrem(622);
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.05em;
  padding-left: 0;
  font-size: rrem(74);
  color: rgba(255, 255, 255, 0.4);
  font-family: $sabuf;
  font-weight: $extrabold;
  letter-spacing: 0.05em;
  transform: skewY(15deg);
  top: rrem(354);
  bottom: 0;
  left: 0;
  right: 0;
  font-style: italic;
  z-index: -1;
  @include mq("md"){
    padding-left: rrem(168);
    font-size: rrem(82);
    top: rrem(290);
  }
}

.reasons__head {
  display: flex;
  flex-direction: column-reverse;
  @include mq("md"){
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: rrem(37);
  }
}

.reasons__head--container {
  width: 100%;
  @include mq("md"){
    max-width: rrem(373);
    margin-bottom: rrem(70);
  }
}

.reasons__head--title {
  font-size: rrem(22);
  font-style: normal;
  font-weight: $regular;
  line-height: calc(33/22);
  margin-top: rrem(21);
  letter-spacing: 0.2em;
  @include mq("md"){
    font-size: rrem(18);
    line-height: calc(27/18);
    position: relative;
    margin-top: 0;
  }

}

.reasons__head--title::before{
  @include mq("md"){
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background: $pink;
    bottom: rrem(-7.5);
    left: 0;
  }
}

.reasons__head--text {
  margin-top: rrem(17);
  font-size: rrem(16);
  font-style: normal;
  font-weight: $normal;
  letter-spacing: 0.05em;
  line-height: 32px;
  line-break: anywhere; 
  @include mq("md"){
    font-size: rrem(14);
    text-align: left;
    margin-top: rrem(22);
  }
}

.reasons__head--image {
  margin-top: rrem(49);
  @include mq("md"){
    aspect-ratio: 590/346;
    width: rrem(590);
    margin-top: 0;
  }
}

.reasons__head--image img{
  object-fit: cover;
  height: 100%;
}

.reasons__card{
  margin-top: rrem(200);
  @include mq("md"){
    margin-top: rrem(487);
  }
}
