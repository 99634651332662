@use "sass:math";
// ================================================
// インナー幅の設定
// ================================================

// コンテンツのインナー幅（Webサイトで一番多いインナー幅で設定）
$inner: 1000px;
// ↑この数字はコーディングするデザインによって書き換えてください

// 余白設定
// PC時の余白はデザインに応じたものではなく、最低取っておいた方が良い余白
$padding-pc: 25px;
// SP時はデザインカンプに沿って書き換えてください
$padding-sp: 20px;

// ================================================
    // 色に関する設定
// ================================================

//色指定（デザインを見てよく使う色は設定！）
$black: #797879;
$white: #FFF;
$pink: #F5817C;
$bg-white:#EFEFEF;
$grade: linear-gradient(to right,rgba(245, 129, 124, 0.91) 0%, #ffc0ad 100%);

body {
  color: $black;
}

// ================================================
  // フォントについての設定
// ================================================
$mainf:"M PLUS 1p", sans-serif;
$sabuf: "Lato", sans-serif;
$sansjp: "Noto Sans JP", sans-serif;
$serifjp: "Noto Serif JP", serif;

body {
  // このサイトの基本フォント
  font-family: $mainf;
}
// フォントウェイト
$normal: 300;
$regular: 400;
$medium: 500;
$bold: 700;
$extrabold: 800;
$black-f: 900;


// ================================================
  // メディアクエリとブレークポイントの設定
// ================================================
//ブレイクポイント(デザインによって異なる)
$breakpoints: (
  sm: 551,
  md: 751,
  lg: 1201,
  xl: 1400,
);


//メディアクエリー
$mediaquerys: (
  sm: "screen and (min-width: #{map-get($breakpoints,'sm')}px)",
  md: "screen and (min-width: #{map-get($breakpoints,'md')}px)",
  lg: "screen and (min-width: #{map-get($breakpoints,'lg')}px)",
  xl: "screen and (min-width: #{map-get($breakpoints,'xl')}px)",
);

// @include mq(){}で書くとブレークポイントが反映される（初期値はmd）
@mixin mq($mediaquery: md) {
  @media #{map-get($mediaquerys, $mediaquery)} {
    @content;
  }
}
/*  PCとSPの表示非表示の切り替え */
/*(md)px以上で表示*/
.pc-only {
  display: none;
  @include mq("md") {
    display: block;
  }
}

/*モバイルのみ表示*/
.sp-only {
  @include mq("sm"){
    display: none;
  }
  @include mq("md") {
    display: none;
  }
}

// ================================================
  // remへ変換
// ================================================
// remへの計算式（16pxを基準としている。10pxを基準とする場合は16を10に変更する）
@function rrem($pixels) {
  @return math.div($pixels, 16) * 1rem;
}

/*画像の縦横比設定*/
img {
  width: 100%;
  height: auto;
  display: block;
}

/* aタグのスタイルリセット*/
a {
  text-decoration: none;
}
a:hover {
  opacity: 0.8;
}