@use "../global" as *;
.menu {
  background: #F4E0DF;
  padding-top: rrem(104.4);
  padding-bottom: rrem(101);
  z-index: 2;
  position: relative;
  @include mq("md"){
    background: none;
    padding-top: rrem(63);
    // z-index: -2;
    z-index: auto;
  }
}

.menu__bg{
  position: absolute;
  z-index: -5;
  background: #F4E0DF;
  width: 100%;
  height: 100%;
  top: 0;
}

.menu::before,
.menu::after,
.menu__inner::before,
.menu__inner::after{
  @include mq("md"){
    position: absolute;
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.8;
  }
}

.menu::before{
  @include mq("md"){
    background-image: url(../images/en_small.png);
    width: rrem(207);
    height: rrem(207);
    top: rrem(28);
    right: rrem(-103);
  }
}

.menu::after{
  @include mq("md"){
    background-image: url(../images/en_big.png);
    width: rrem(696);
    height: rrem(696);
    top: rrem(132);
    right: rrem(-455);
  }
}

.menu__inner{
  position: relative;
}

.menu__inner::before{
  @include mq("md"){
    background-image: url(../images/en_big.png);
    width: rrem(449);
    height: rrem(449);
    top: rrem(95);
    left: calc(20% - 50vw);
    z-index: -1;
  }
}

.menu__inner::after{
  @include mq("md"){
    background-image: url(../images/en_small.png);
    width: rrem(207);
    height: rrem(207);
    bottom: rrem(30);
    left: calc(37% - 50vw);
    z-index: -1;
  }
}

.menu__secTitle {
  aspect-ratio: 191/88;
  width: 100%;
  max-width: rrem(96);
  margin-inline: auto;
}

.menu__secTitle img{
  object-fit: cover;
  height: 100%;
}

.menu__text {
  margin-top: rrem(72);
  padding-left: rrem(17);
  position: relative;
  font-size: rrem(13);
}

.menu__text::before {
  position: absolute;
  content: "※";
  top: 0;
  left: 0;
}


