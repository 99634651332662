@use "../global" as *;
.btn {
  padding: rrem(22) 0;
  position: relative;
  display: inline-block;
  font-size: rrem(13);
  font-weight: $bold;
  line-height: 1;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  background-color: #F8ABA6;
  width: 100%;
  color: $white;
  @include mq("md"){
    width: rrem(358);
  }
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  right: rrem(32);
  width: 0;
  height: 0;
  display: inline-block;
  background-image: url(../images/btn_arrow.png);
  width: rrem(35);
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: transform 0.3s ease;
}

.btn:hover::before{
  transform: translateX(5px);
}

.btn__black {
  padding: rrem(22) 0;
  width: 100%;
  position: relative;
  display: inline-block;
  font-size: rrem(15);
  font-weight: $regular;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background-color: #D8D8D8;
  color: $black;
  @include mq("md"){
  width: rrem(358);
  }
}

.btn__black::before {
  content: "";
  position: absolute;
  top: 0;
  right: rrem(32);
  width: 0;
  height: 0;
  display: inline-block;
  background-image: url(../images/btn_arrow02.png);
  width: rrem(35);
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: transform 0.3s ease;
}

.btn__black:hover::before {
  transform: translateX(5px);
}

