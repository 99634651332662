@use "../global" as *;
.voice {
  padding-top: rrem(104.4);
  padding-bottom: rrem(101);
  position: relative;
  // z-index: -2;
  @include mq("md"){
  padding-top: rrem(63);
  }
}

.voice::before{
  position: absolute;
  content: "";
  background: #F8ABA6;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  top: 0;
}

.voice__secTitle {
  aspect-ratio: 165/75;
  width: 100%;
  max-width: rrem(83);
  margin-inline: auto;
}

.voice__secTitle img{
  object-fit: cover;
  height: 100%;
}

