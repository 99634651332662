@charset "UTF-8";
/* リキッドレイアウト対応のための設定 */
body {
  color: #797879;
}

body {
  font-family: "M PLUS 1p", sans-serif;
}

/*  PCとSPの表示非表示の切り替え */
/*(md)px以上で表示*/
.pc-only {
  display: none;
}
@media screen and (min-width: 751px) {
  .pc-only {
    display: block;
  }
}

/*モバイルのみ表示*/
@media screen and (min-width: 551px) {
  .sp-only {
    display: none;
  }
}
@media screen and (min-width: 751px) {
  .sp-only {
    display: none;
  }
}

/*画像の縦横比設定*/
img {
  width: 100%;
  height: auto;
  display: block;
}

/* aタグのスタイルリセット*/
a {
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
}

html {
  font-size: 16px;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 751px) {
  html {
    font-size: 1.6vw;
  }
}
@media (min-width: 1000px) {
  html {
    font-size: 16px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Set core html defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  width: 100%;
  height: auto;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

/* フォームリセット */
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.btn {
  padding: 1.375rem 0;
  position: relative;
  display: inline-block;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  background-color: #F8ABA6;
  width: 100%;
  color: #FFF;
}
@media screen and (min-width: 751px) {
  .btn {
    width: 22.375rem;
  }
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  right: 2rem;
  width: 0;
  height: 0;
  display: inline-block;
  background-image: url(../images/btn_arrow.png);
  width: 2.1875rem;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: transform 0.3s ease;
}

.btn:hover::before {
  transform: translateX(5px);
}

.btn__black {
  padding: 1.375rem 0;
  width: 100%;
  position: relative;
  display: inline-block;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background-color: #D8D8D8;
  color: #797879;
}
@media screen and (min-width: 751px) {
  .btn__black {
    width: 22.375rem;
  }
}

.btn__black::before {
  content: "";
  position: absolute;
  top: 0;
  right: 2rem;
  width: 0;
  height: 0;
  display: inline-block;
  background-image: url(../images/btn_arrow02.png);
  width: 2.1875rem;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: transform 0.3s ease;
}

.btn__black:hover::before {
  transform: translateX(5px);
}

.card {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 7.5rem;
}
@media screen and (min-width: 751px) {
  .card {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 3.75rem;
    margin-top: 21.25rem;
    position: relative;
  }
}

.card:nth-child(1)::after,
.card:nth-child(3n)::after {
  position: absolute;
  content: "";
  background-image: url(../images/rayout__en.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 27.375rem;
  height: 26.9375rem;
}

.card:nth-child(1)::after {
  right: -26.9375rem;
  top: 31.875rem;
}

.card:nth-child(3n)::after {
  left: -26.9375rem;
  top: -31.875rem;
}

@media screen and (min-width: 751px) {
  .card:nth-child(1) {
    margin-top: 0;
  }
}

@media screen and (min-width: 751px) {
  .card:nth-child(even) {
    flex-direction: row-reverse;
  }
}

.card:nth-child(even) .card__subTitle,
.card:nth-child(even) .card__text {
  color: #797879;
  line-break: anywhere;
}

.card__container {
  width: 100%;
  position: relative;
  margin-top: 4.375rem;
}
@media screen and (min-width: 751px) {
  .card__container {
    max-width: 28.75rem;
    margin-top: 0;
  }
}

.card:nth-child(even)::before {
  position: absolute;
  top: 128.4375rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #EFEFEF;
  transform: skewY(15deg);
  z-index: -1;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  height: 38.875rem;
  content: "";
}
@media screen and (min-width: 751px) {
  .card:nth-child(even)::before {
    top: -9.125rem;
    z-index: -2;
  }
}

.card__subTitle {
  font-weight: 500;
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  text-align: left;
  color: #fafafa;
}
@media screen and (min-width: 751px) {
  .card__subTitle {
    letter-spacing: 0.2em;
  }
}

.card__text {
  font-weight: 400;
  font-size: 0.8125rem;
  letter-spacing: 0.05em;
  text-align: left;
  line-height: 32px;
  color: #FFF;
  margin-top: 4.8125rem;
}
@media screen and (min-width: 751px) {
  .card__text {
    margin-top: 3.8125rem;
  }
}

.card__titleImage {
  position: absolute;
  content: "";
}

.card__titleImage img {
  object-fit: cover;
  height: 100%;
}

.card__titleImage--01 {
  top: -1rem;
  right: -1.25rem;
  aspect-ratio: 423/351;
  width: 11.75rem;
}
@media screen and (min-width: 751px) {
  .card__titleImage--01 {
    top: -3.8125rem;
    right: -2.0625rem;
  }
}

.card__image {
  aspect-ratio: 480/314;
  box-shadow: 15px 15px 0px 0px rgb(255, 255, 255);
}
@media screen and (min-width: 751px) {
  .card__image {
    width: 30rem;
  }
}

.card:nth-child(even) .card__image {
  box-shadow: 15px 15px 0px 0px rgb(248, 171, 166);
}
@media screen and (min-width: 751px) {
  .card:nth-child(even) .card__image {
    box-shadow: -15px 15px 0px 0px rgb(248, 171, 166);
  }
}

.card__image img {
  object-fit: cover;
  height: 100%;
}

.card__titleImage--02 {
  aspect-ratio: 528/276;
  width: 16.0625rem;
  right: 0;
  bottom: -6.875rem;
}
@media screen and (min-width: 751px) {
  .card__titleImage--02 {
    bottom: -8.875rem;
  }
}

.card__titleImage--03 {
  aspect-ratio: 406/182;
  width: 12.625rem;
  right: 0;
}
@media screen and (min-width: 751px) {
  .card__titleImage--03 {
    bottom: -8.75rem;
  }
}

@media screen and (min-width: 751px) {
  .card:nth-child(3n) .card__container {
    top: -6.25rem;
  }
}

html, body {
  overflow-x: hidden;
}

.contact {
  background: #F5F5F5;
}

.floating {
  display: none;
}
@media screen and (min-width: 751px) {
  .floating {
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 0;
    display: flex;
    top: 7.3125rem;
    display: block;
    width: 3.625rem;
  }
}

.floating__link {
  width: 50%;
  height: 6.25rem;
}
@media screen and (min-width: 751px) {
  .floating__link {
    height: 12.6875rem;
    width: 3.625rem;
  }
}

.floating__link01 {
  background: #FC8C84;
  border: 2px solid #FC8C84;
  transition: all 0.3s 0s ease;
  display: grid;
  place-items: center;
}
@media screen and (min-width: 751px) {
  .floating__link01 {
    border-radius: 20px 0px 0px 0px;
    margin-bottom: 0.125rem;
  }
}

.floating__link01:hover {
  opacity: 0.6;
}

.floating__link02 {
  background: #FCDCDA;
  border: 2px solid #FCDCDA;
  transition: all 0.3s 0s ease;
  display: grid;
  place-items: center;
}
@media screen and (min-width: 751px) {
  .floating__link02 {
    border-radius: 0px 0px 0px 20px;
  }
}

.floating__link02:hover {
  opacity: 0.6;
}

.floating__text01 {
  color: #FFF;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75;
}
@media screen and (min-width: 751px) {
  .floating__text01 {
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -ms-writing-mode: vertical-rl;
    color: #FFF;
    font-size: 0.8125rem;
    line-height: 1.5;
    letter-spacing: 0.1875rem;
    position: relative;
  }
}

.floating__text02 {
  color: #707070;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75;
}
@media screen and (min-width: 751px) {
  .floating__text02 {
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -ms-writing-mode: vertical-rl;
    font-size: 0.8125rem;
    line-height: 1.5;
    position: relative;
    letter-spacing: 0.1875rem;
  }
}

.floating-sp {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}
@media screen and (min-width: 751px) {
  .floating-sp {
    display: none;
  }
}

.floating-sp__inner {
  display: flex;
}

.floating-sp__link {
  width: 50%;
  height: 6.25rem;
  transition: all 0.3s 0s ease;
  display: grid;
  place-items: center;
}

.floating-sp__inner a {
  opacity: 1;
}

.floating-sp__link01 {
  background: #FC8C84;
  color: #FFF;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75;
}

.floating-sp__modal {
  background: #FCDCDA;
  color: #707070;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75;
}

/* モーダルCSS */
.modal {
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* モーダル全体のスクロールを無効にする */
  overflow-y: auto;
  background: #fff;
}

.modal__Bg {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.modal__Wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 500px;
  background-color: #fff;
  border: 6px solid rgba(252, 140, 132, 0.5);
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-top: 50px;
}
@media screen and (min-width: 551px) {
  .modal__Wrapper {
    padding-top: 8.6875rem;
    padding-bottom: 7.8125rem;
  }
}

.modal__Contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

.Modal__close {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
  font-size: 3.375rem;
  color: #F8ABA6;
}

.floating-sp__lesson {
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: center;
  color: #fafafa;
  background: #F8ABA6;
  display: block;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
}
@media screen and (min-width: 551px) {
  .floating-sp__lesson {
    font-size: 1.25rem;
  }
}

@media (max-width: 750px) {
  .modal-open {
    position: fixed;
    width: 100%;
    overflow: hidden; /* モーダルが表示されているときにスクロールを無効にする */
  }
}
/* 以下ボタンスタイル */
button {
  cursor: pointer;
}

.footer {
  background-color: #F8ABA6;
  padding-bottom: 6.25rem;
}
@media screen and (min-width: 751px) {
  .footer {
    padding-bottom: 0.4375rem;
  }
}

.footer__inner {
  margin-left: 2.8125rem;
  margin-right: 1.5625rem;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
@media screen and (min-width: 751px) {
  .footer__inner {
    padding-bottom: 0;
  }
}

.footer__wrap {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1201px) {
  .footer__wrap {
    padding-top: 4.8125rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.9375rem;
  }
}

.footer__container {
  display: flex;
  gap: 3.125rem;
  margin-inline: auto;
}
@media screen and (min-width: 751px) {
  .footer__container {
    margin-inline: 0;
  }
}
@media screen and (min-width: 1201px) {
  .footer__container {
    gap: 1.875rem;
  }
}

.footer__logo {
  max-width: 4.625rem;
  width: 100%;
}

.footer__logo a {
  display: block;
}

.footer__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer__nav {
  margin-top: 1.875rem;
}
@media screen and (min-width: 1201px) {
  .footer__nav {
    margin-top: 0;
  }
}

.footer__nav-items {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  text-align: center;
}
@media screen and (min-width: 751px) {
  .footer__nav-items {
    flex-direction: row;
    align-items: flex-start;
    gap: 1.875rem;
    text-align: left;
  }
}

.footer__nav-item a {
  position: relative;
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  color: #FAFAFA;
}
@media screen and (min-width: 751px) {
  .footer__nav-item a {
    text-align: center;
  }
}

.footer__nav-item a img {
  width: 1.25rem;
}

.footer__company {
  font-size: 1.25rem;
  font-weight: 400;
  font-family: "Noto Sans JP", sans-serif;
  color: #FAFAFA;
}

.footer__company--text {
  color: #FAFAFA;
}

.footer__company--tel {
  color: #FAFAFA;
}
@media screen and (min-width: 751px) {
  .footer__company--tel {
    pointer-events: none;
  }
}

.footer__nav-itemicon {
  display: flex;
  gap: 1.875rem;
  margin-inline: auto;
}
@media screen and (min-width: 751px) {
  .footer__nav-itemicon {
    margin-inline: 0;
  }
}

.footer__copyright {
  margin-top: 0.625rem;
  display: block;
  font-size: 0.75rem;
  line-height: 1;
  letter-spacing: 0.04em;
  text-align: center;
  color: #FAFAFA;
}
@media screen and (min-width: 751px) {
  .footer__copyright {
    margin-top: 3.125rem;
  }
}

.footer__copyright span {
  text-transform: uppercase;
}

.fv {
  position: relative;
  min-height: 25rem;
  background: #EFEFEF;
  z-index: -2;
}
@media screen and (min-width: 751px) {
  .fv {
    margin-top: -5.3125rem;
    z-index: 0;
  }
}

.fv__inner {
  height: inherit;
  min-height: inherit;
}

.fv__title-wrap {
  width: 100%;
  text-align: center;
  padding-left: 2.1875rem;
  position: relative;
  padding-top: 4.7125rem;
  padding-bottom: 5.6rem;
}
@media screen and (min-width: 751px) {
  .fv__title-wrap {
    position: absolute;
    z-index: 2;
    top: 47%;
    left: calc(50% - min(50vw, 34.375rem));
    transform: translateY(-50%);
    width: initial;
    margin-left: 25px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.fv__main-title {
  font-size: 6vw;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-align: left;
  text-shadow: 0px 1px 1px #fff;
}
@media screen and (min-width: 751px) {
  .fv__main-title {
    font-size: 1.75rem;
    line-height: 1.5;
    color: #949293;
    text-align: start;
  }
}

.fv__main-title span {
  color: #F8ABA6;
}

.fv__sub-title {
  font-size: 4vw;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
  text-shadow: 0px 1px 1px #fff;
}
@media screen and (min-width: 751px) {
  .fv__sub-title {
    font-size: 1.375rem;
    line-height: 1.5;
    color: #949293;
    text-align: start;
  }
}

.fv__img,
.fv__img img {
  height: inherit;
  min-height: inherit;
}

.fv__img {
  aspect-ratio: 377/474;
}
@media screen and (min-width: 751px) {
  .fv__img {
    aspect-ratio: 1440/900;
  }
}

.fv__img img {
  object-fit: cover;
  height: 100%;
}

.fv__title-img {
  position: absolute;
  width: 25.75rem;
  aspect-ratio: 412/258.4;
  top: 0.9375rem;
  right: 0;
  z-index: -1;
  opacity: 0.6;
}
@media screen and (min-width: 751px) {
  .fv__title-img {
    top: 0;
    right: calc(122% - min(50vw, 34.375rem));
    aspect-ratio: 481/308;
    width: 15.0625rem;
    opacity: 1;
  }
}

.fv__title-img img {
  object-fit: cover;
  height: 100%;
}

.header {
  height: 5.625rem;
  background-color: #EFEFEF;
  position: relative;
}
@media screen and (min-width: 751px) {
  .header {
    height: 2.5rem;
    margin-top: 2.8125rem;
    background: none;
  }
}

.header__inner {
  position: relative;
}

.header__logo {
  position: absolute;
  content: "";
  width: 20%;
  max-width: 4.625rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0.625rem;
}
@media screen and (min-width: 751px) {
  .header__logo {
    position: absolute;
    content: "";
    width: 4.625rem;
    height: 4.625rem;
    top: -50%;
    left: 2.5rem;
    z-index: 5;
    transform: translateY(0);
  }
}
@media screen and (min-width: 1201px) {
  .header__logo {
    left: 1.875rem;
  }
}

.header__logo--images {
  aspect-ratio: 94/94;
}
@media screen and (min-width: 751px) {
  .header__logo--images {
    aspect-ratio: 74/74;
  }
}

.header__logo img {
  object-fit: cover;
  height: 100%;
}

.header__title {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 10vw;
  letter-spacing: 0.05em;
  line-height: 1.5;
  text-align: center;
  color: #f8aba6;
  padding-top: 0.8125rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}
@media screen and (min-width: 551px) {
  .header__title {
    padding-top: 0;
    font-size: 3.625rem;
  }
}
@media screen and (min-width: 751px) {
  .header__title {
    display: none;
  }
}

.header__icon {
  display: none;
}
@media screen and (min-width: 751px) {
  .header__icon {
    display: flex;
    gap: 0.5rem;
  }
}
@media screen and (min-width: 1201px) {
  .header__icon {
    gap: 0.75rem;
  }
}

.header__icon--link {
  aspect-ratio: 31/31;
}

.header__icon--link img {
  object-fit: cover;
  height: 100%;
  width: 1.9375rem;
  height: 1.9375rem;
}

.header__nav {
  display: none;
}
@media screen and (min-width: 751px) {
  .header__nav {
    position: absolute;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 53rem;
    height: 1.875rem;
    z-index: 5;
  }
}
@media screen and (min-width: 1201px) {
  .header__nav {
    width: 100%;
  }
}

.header__nav-list {
  display: flex;
  justify-content: center;
  background: linear-gradient(to right, rgba(245, 129, 124, 0.91) 0%, #ffc0ad 100%);
  border-radius: 50px;
  padding: 0.625rem 0;
}
@media screen and (min-width: 1201px) {
  .header__nav-list {
    padding: 0.375rem 1.1875rem 0.3125rem 1.5rem;
    gap: 3.5rem;
    align-items: center;
    width: 100%;
  }
}

.header__nav-item {
  height: inherit;
  margin: 0 13px;
}
@media screen and (min-width: 1201px) {
  .header__nav-item {
    margin: 0;
  }
}

.header__nav-item a {
  height: inherit;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: "M PLUS 1p", sans-serif;
  line-height: 1;
  color: #fff;
  letter-spacing: -1;
}

.header__hamburger {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  z-index: 999;
  width: 20%;
  max-width: 5.625rem;
  height: 5.625rem;
  background: linear-gradient(to right, rgba(245, 129, 124, 0.91) 0%, #ffc0ad 100%);
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (min-width: 751px) {
  .header__hamburger {
    display: none;
  }
}

.header__hamburger.is-open {
  background-color: transparent;
  background: none;
  position: fixed;
}

.header__hamburger span {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 2.625rem;
  height: 1px;
  background-color: #fff;
  transition: 0.5s;
}

.header__hamburger span:nth-of-type(1) {
  top: -0.875rem;
}

.header__hamburger span:nth-of-type(2) {
  top: 0;
}

.header__hamburger span:nth-of-type(3) {
  top: 0.875rem;
}

.header__hamburger.is-open span:nth-of-type(1) {
  top: 0;
  transform: translateX(-50%) rotate(45deg);
}

.header__hamburger.is-open span:nth-of-type(2) {
  opacity: 0;
}

.header__hamburger.is-open span:nth-of-type(3) {
  top: -0.125rem;
  transform: translateX(-50%) rotate(-45deg);
}

.header__drawer {
  display: none;
  position: fixed;
  z-index: 900;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #FFF;
  overflow-y: scroll;
  scrollbar-width: none;
}

.header__drawer-overlay {
  position: absolute;
  content: "";
  background: linear-gradient(90deg, rgba(245, 129, 124, 0.8) 0%, rgba(255, 192, 173, 0.8) 100%);
  width: 100%;
  z-index: -1;
  top: 0;
  padding-top: 4.25rem;
  padding-bottom: 12.5rem;
}

.header__drawer.is-open {
  background: initial !important;
}

.header__drawer::-webkit-scrollbar {
  display: none;
}

.header__drawer-item a {
  padding: 1.25rem 0;
  display: block;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.9;
}
@media screen and (min-width: 551px) {
  .header__drawer-item a {
    font-size: 1.875rem;
  }
}

.header__logo--sp {
  margin-inline: auto;
  margin-bottom: 3.125rem;
  width: 4.625rem;
  height: 4.625rem;
}

.header__spTitle {
  position: absolute;
  content: "";
  z-index: -1;
  left: -10.3125rem;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 8.0625rem;
  letter-spacing: 0.05em;
  transform: rotate(90deg);
  color: rgba(0, 0, 0, 0);
  text-shadow: 3px 3px 0 #ffc0ad;
  -webkit-text-stroke: 1px #FFF;
  text-stroke: 1px #FFF;
}

.header__lesson {
  background: linear-gradient(to bottom, rgba(245, 129, 124, 0.91) 0%, #ffc0ad 100%);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-top: -1.25rem;
  padding: 0.25rem;
}
@media screen and (min-width: 1201px) {
  .header__lesson {
    width: 5rem;
    height: 5rem;
  }
}

.header__lessonlink {
  font-size: 0.5rem;
  color: #fff;
  text-align: center;
  display: grid;
  height: 4rem;
  place-items: center;
  margin-top: -2px;
}
@media screen and (min-width: 1201px) {
  .header__lessonlink {
    font-size: 0.8125rem;
    height: 5rem;
  }
}

.inner {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 751px) {
  .inner {
    max-width: 1050px;
    padding-right: 25px;
    padding-left: 25px;
  }
}

.instructor {
  padding-top: 6.075rem;
  padding-bottom: 8.75rem;
  background: linear-gradient(to right, rgba(245, 129, 124, 0.91) 0%, #ffc0ad 100%);
  position: relative;
  z-index: -1;
}
@media screen and (min-width: 751px) {
  .instructor {
    z-index: auto;
    padding-bottom: 11.8125rem;
    background: none;
  }
}

.instructor__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -5;
  background: linear-gradient(to right, rgba(245, 129, 124, 0.91) 0%, #ffc0ad 100%);
}

.instructor__inner {
  position: relative;
}

.instructor__inner::before {
  position: absolute;
  content: "INSTRUCTOR";
  top: -6.25rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #EFEFEF;
  transform: rotate(-10deg);
  z-index: -1;
  margin: 0 calc(50% - 50vw);
  height: 57.6575rem;
  display: flex;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  box-shadow: 10px 10px 0px #f8aba6;
  color: transparent;
  -webkit-text-stroke: 2px #F8ABA6;
  text-stroke: 2px #F8ABA6;
  justify-content: end;
}
@media screen and (min-width: 751px) {
  .instructor__inner::before {
    justify-content: center;
    font-size: 3.9375rem;
    top: -3.125rem;
    padding-left: 12.25rem;
  }
}

.instructor__name {
  aspect-ratio: 401/125;
  width: 18.75rem;
  margin-inline: auto;
}
@media screen and (min-width: 551px) {
  .instructor__name {
    width: 24.625rem;
  }
}
@media screen and (min-width: 751px) {
  .instructor__name {
    margin-left: calc(50% - 18vw);
  }
}

.instructor__name img {
  object-fit: cover;
  height: 100%;
}

@media screen and (min-width: 751px) {
  .instructor__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 5.0625rem;
    position: relative;
  }
}

.instructor__image {
  aspect-ratio: 840/1438;
  max-width: 26.25rem;
  width: 100%;
}
@media (max-width: 751px) {
  .instructor__image {
    margin-inline: auto;
  }
}
@media screen and (min-width: 751px) {
  .instructor__image {
    margin-right: calc(50% - 50vw);
  }
}

.instructor__image img {
  object-fit: cover;
  height: 100%;
}

.instructor__textBox {
  padding-bottom: 3.125rem;
  line-height: 3;
  background: #EFEFEF;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
@media screen and (min-width: 751px) {
  .instructor__textBox {
    width: 100%;
    max-width: 25rem;
    background: none;
    padding-right: 0;
    padding-left: 0;
    margin-top: 5.125rem;
  }
}

.instructor__text {
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.03em;
  margin-top: 3.125rem;
}
@media screen and (min-width: 751px) {
  .instructor__text {
    margin-top: 0;
    letter-spacing: 0.05em;
  }
}

.instructor__text--02,
.instructor__text--03 {
  margin-top: 1.875rem;
}
@media screen and (min-width: 751px) {
  .instructor__text--02,
  .instructor__text--03 {
    margin-top: 1.875rem;
  }
}

.layout-header {
  position: fixed;
  z-index: 900;
  top: 2.8125rem;
  right: 0.5rem;
}

.lesson {
  position: relative;
  padding-top: 2.9375rem;
  padding-bottom: 9rem;
}

.lesson::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background-image: url(../images/lesson_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  z-index: -2;
  filter: brightness(0.9);
}
@media screen and (min-width: 751px) {
  .lesson::before {
    background-position: center;
  }
}

.lesson__secTitle {
  width: 100%;
  max-width: 6.5rem;
  margin-inline: auto;
  aspect-ratio: 210/70;
}

.lesson__secTitle img {
  object-fit: cover;
  height: 100%;
}

.lesson__list {
  width: 100%;
  line-height: 1;
  text-align: center;
}

.lesson__list td {
  letter-spacing: 0.06em;
}

.lesson__list:first-of-type {
  margin-top: 3.0625rem;
}

.lesson__list:last-child {
  margin-top: 1.25rem;
}

.lesson__list tr:not(:first-child),
.lesson__list--02 tr {
  height: 4rem;
}

.lesson__list--head {
  width: 50%;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background: linear-gradient(to right, rgba(245, 129, 124, 0.91) 0%, #ffc0ad 100%);
}

.lesson__list--data {
  padding: 0.625rem 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background: #FFF;
  width: 50%;
}
@media screen and (min-width: 751px) {
  .lesson__list--data {
    padding: 0.9375rem 0;
    font-size: 1rem;
    line-height: 1.5;
  }
}

.lesson__list--02 {
  margin-top: 1.25rem;
}

.lesson__textBox {
  margin-top: 4rem;
}

.lesson__text {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 2;
  padding-left: 0.875rem;
  position: relative;
  letter-spacing: 0.05em;
}

.lesson__text::before {
  position: absolute;
  content: "※";
  left: 0;
}

.lesson__btn {
  margin-top: 9.5rem;
  text-align: center;
}

.menu {
  background: #F4E0DF;
  padding-top: 6.525rem;
  padding-bottom: 6.3125rem;
  z-index: 2;
  position: relative;
}
@media screen and (min-width: 751px) {
  .menu {
    background: none;
    padding-top: 3.9375rem;
    z-index: auto;
  }
}

.menu__bg {
  position: absolute;
  z-index: -5;
  background: #F4E0DF;
  width: 100%;
  height: 100%;
  top: 0;
}

@media screen and (min-width: 751px) {
  .menu::before,
  .menu::after,
  .menu__inner::before,
  .menu__inner::after {
    position: absolute;
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.8;
  }
}

@media screen and (min-width: 751px) {
  .menu::before {
    background-image: url(../images/en_small.png);
    width: 12.9375rem;
    height: 12.9375rem;
    top: 1.75rem;
    right: -6.4375rem;
  }
}

@media screen and (min-width: 751px) {
  .menu::after {
    background-image: url(../images/en_big.png);
    width: 43.5rem;
    height: 43.5rem;
    top: 8.25rem;
    right: -28.4375rem;
  }
}

.menu__inner {
  position: relative;
}

@media screen and (min-width: 751px) {
  .menu__inner::before {
    background-image: url(../images/en_big.png);
    width: 28.0625rem;
    height: 28.0625rem;
    top: 5.9375rem;
    left: calc(20% - 50vw);
    z-index: -1;
  }
}

@media screen and (min-width: 751px) {
  .menu__inner::after {
    background-image: url(../images/en_small.png);
    width: 12.9375rem;
    height: 12.9375rem;
    bottom: 1.875rem;
    left: calc(37% - 50vw);
    z-index: -1;
  }
}

.menu__secTitle {
  aspect-ratio: 191/88;
  width: 100%;
  max-width: 6rem;
  margin-inline: auto;
}

.menu__secTitle img {
  object-fit: cover;
  height: 100%;
}

.menu__text {
  margin-top: 4.5rem;
  padding-left: 1.0625rem;
  position: relative;
  font-size: 0.8125rem;
}

.menu__text::before {
  position: absolute;
  content: "※";
  top: 0;
  left: 0;
}

.pagetop {
  position: fixed;
  width: 2.625rem;
  z-index: 10;
  right: 0;
  transform: translateX(-50%);
  bottom: 7rem;
}
@media screen and (min-width: 751px) {
  .pagetop {
    width: 2.625rem;
    left: initial;
    transform: initial;
    right: 5%;
    bottom: 0.3125rem;
  }
}

.pagetop__link {
  transition: all 0.3s 0s ease;
  aspect-ratio: 42/42;
  width: 2.625rem;
}

.pagetop__link {
  object-fit: cover;
  height: 100%;
}

.qa-list__item {
  background-color: #fff;
  border-bottom: 1px solid #707070;
  padding-bottom: 2.4375rem;
}

.qa-list__item + .qa-list__item {
  margin-top: 2.5rem;
}

.qa-list__item-question {
  position: relative;
  font-size: 0.9375rem;
  text-indent: -1.25rem;
  cursor: pointer;
  position: relative;
  padding: 0.625rem 1.25rem;
  border-left: 2px solid #707070;
}
@media screen and (min-width: 751px) {
  .qa-list__item-question {
    padding: 0.625rem 3.75rem 0.625rem 1.25rem;
    display: flex;
    align-items: center;
  }
}

.qa-list__item-question::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.25rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.1875rem solid #707070;
  border-right: 0.1875rem solid #707070;
  transform: translateY(-50%) rotate(135deg);
  transition: 0.3s;
}
@media screen and (min-width: 751px) {
  .qa-list__item-question::before {
    width: 0.8125rem;
    height: 0.8125rem;
  }
}

.qa-list__item-question.is-open::before {
  transform: translateY(-50%) rotate(315deg);
}

.qa-list__item-question span {
  margin-right: 1.25rem;
}

.qa-list__item-answer {
  display: none;
  font-size: 0.8125rem;
  text-indent: -1.25rem;
  padding: 0.625rem 2.25rem 0.625rem 1.25rem;
  border-left: 1px solid #707070;
  margin-left: 1px;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 751px) {
  .qa-list__item-answer {
    padding: 2.5rem 2.5rem 2.5rem 1.25rem;
  }
}

.qa-list__item-answer span {
  margin-right: 1.25rem;
}

.qa {
  padding-top: 6.525rem;
  margin-bottom: 8.60625rem;
  background: #FFF;
}
@media screen and (min-width: 751px) {
  .qa {
    padding-top: 4.525rem;
    background: none;
  }
}

.qa__inner {
  margin: auto;
  padding: 0 1.5625rem;
  max-width: 53.125rem;
  width: 100%;
}

.qa__secTitle {
  aspect-ratio: 309/82;
  width: 10rem;
  margin-inline: auto;
}

.qa__secTitle img {
  object-fit: cover;
  height: 100%;
}

.qa__list {
  margin-top: 8.1625rem;
  margin-bottom: 8.60625rem;
}

.reasons {
  padding-bottom: 8.125rem;
  position: relative;
}
@media screen and (min-width: 751px) {
  .reasons {
    padding-bottom: 5.1875rem;
  }
}

.reasons::before {
  position: absolute;
  content: "";
  background: linear-gradient(to right, rgb(245, 129, 124) 0%, #ffc0ad 100%);
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 0.7;
}

.reasons__wrapper {
  width: 100%;
  margin: 0;
  padding-top: 7.9375rem;
  position: relative;
}

.reasons__wrapper::before {
  position: absolute;
  content: "";
  top: 16.125rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #EFEFEF;
  transform: skewY(15deg);
  z-index: -1;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  height: 38.875rem;
  display: flex;
  align-items: end;
  padding-left: 12.5rem;
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  font-style: italic;
  padding-bottom: 0.625rem;
}
@media screen and (min-width: 751px) {
  .reasons__wrapper::before {
    content: "mgm.popが選ばれる理由";
    z-index: -2;
    top: 12.125rem;
  }
}

.reasons__wrapper::after {
  position: absolute;
  content: "REASONS";
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  height: 38.875rem;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.05em;
  padding-left: 0;
  font-size: 4.625rem;
  color: rgba(255, 255, 255, 0.4);
  font-family: "Lato", sans-serif;
  font-weight: 800;
  letter-spacing: 0.05em;
  transform: skewY(15deg);
  top: 22.125rem;
  bottom: 0;
  left: 0;
  right: 0;
  font-style: italic;
  z-index: -1;
}
@media screen and (min-width: 751px) {
  .reasons__wrapper::after {
    padding-left: 10.5rem;
    font-size: 5.125rem;
    top: 18.125rem;
  }
}

.reasons__head {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 751px) {
  .reasons__head {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2.3125rem;
  }
}

.reasons__head--container {
  width: 100%;
}
@media screen and (min-width: 751px) {
  .reasons__head--container {
    max-width: 23.3125rem;
    margin-bottom: 4.375rem;
  }
}

.reasons__head--title {
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 1.3125rem;
  letter-spacing: 0.2em;
}
@media screen and (min-width: 751px) {
  .reasons__head--title {
    font-size: 1.125rem;
    line-height: 1.5;
    position: relative;
    margin-top: 0;
  }
}

@media screen and (min-width: 751px) {
  .reasons__head--title::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background: #F5817C;
    bottom: -0.46875rem;
    left: 0;
  }
}

.reasons__head--text {
  margin-top: 1.0625rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 32px;
  line-break: anywhere;
}
@media screen and (min-width: 751px) {
  .reasons__head--text {
    font-size: 0.875rem;
    text-align: left;
    margin-top: 1.375rem;
  }
}

.reasons__head--image {
  margin-top: 3.0625rem;
}
@media screen and (min-width: 751px) {
  .reasons__head--image {
    aspect-ratio: 590/346;
    width: 36.875rem;
    margin-top: 0;
  }
}

.reasons__head--image img {
  object-fit: cover;
  height: 100%;
}

.reasons__card {
  margin-top: 12.5rem;
}
@media screen and (min-width: 751px) {
  .reasons__card {
    margin-top: 30.4375rem;
  }
}

.studeio {
  padding-top: 7.5rem;
  padding-bottom: 9.4375rem;
  position: relative;
}

.studeio::before {
  position: absolute;
  content: "";
  background: #EFEFEF;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 751px) {
  .studeio::before {
    z-index: -2;
  }
}

.studeio__inner {
  position: relative;
}

.studeio__inner::before {
  position: absolute;
  content: "";
  background-image: url(../images/studeio_bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.studeio__btn {
  text-align: center;
  display: grid;
  place-items: center;
  gap: 3.75rem;
}

.studeio__secTitle {
  width: 100%;
  max-width: 8.4375rem;
  margin-inline: auto;
  aspect-ratio: 270/65;
  margin-top: 5.625rem;
}

.studeio__secTitle img {
  object-fit: cover;
  height: 100%;
}

.studeio__text {
  text-align: center;
  margin-top: 3.3125rem;
  letter-spacing: 0.05em;
  font-size: 0.875rem;
}

.studeio__btn--02 {
  text-align: center;
  display: grid;
  place-items: center;
  gap: 0.5rem;
  margin-top: 5rem;
}

.sub-inner {
  padding: 0 1.5625rem;
}
@media screen and (min-width: 551px) {
  .sub-inner {
    padding: 0 5rem;
  }
}
@media screen and (min-width: 751px) {
  .sub-inner {
    max-width: 53.125rem;
    margin-inline: auto;
    width: 100%;
    padding: 0 1.5625rem;
  }
}

.text-box__list:nth-of-type(1) {
  margin-top: 3.5625rem;
}

.text-box__list {
  margin-top: 4.5rem;
}
.text-box__title {
  font-size: 1.125rem;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.2em;
}

.text-box__text {
  margin-top: 1.4375rem;
  font-size: 0.8125rem;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.05em;
}

.top-title__side {
  display: none;
}
@media screen and (min-width: 751px) {
  .top-title__side {
    position: fixed;
    display: block;
    font-family: Lato;
    font-weight: 900;
    font-style: italic;
    font-size: 129px;
    letter-spacing: 0.05em;
    text-align: left;
    color: rgba(0, 0, 0, 0);
    text-shadow: 3px 3px 0 #ffc0ad;
    -webkit-text-stroke: 1px #FFF;
    text-stroke: 1px #FFF;
    top: 50%;
    left: 0;
    transform: rotate(90deg) translateX(-20%) translateY(100%);
    z-index: -1;
    mix-blend-mode: overlay;
    opacity: 0.6;
  }
}

.voice {
  padding-top: 6.525rem;
  padding-bottom: 6.3125rem;
  position: relative;
}
@media screen and (min-width: 751px) {
  .voice {
    padding-top: 3.9375rem;
  }
}

.voice::before {
  position: absolute;
  content: "";
  background: #F8ABA6;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  top: 0;
}

.voice__secTitle {
  aspect-ratio: 165/75;
  width: 100%;
  max-width: 5.1875rem;
  margin-inline: auto;
}

.voice__secTitle img {
  object-fit: cover;
  height: 100%;
}